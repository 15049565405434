import type {
  DetailedHTMLProps,
  FormHTMLAttributes,
  HTMLAttributes,
} from "react";

import { ConfirmPage, EnterEmailPage } from "./login";

export type DivPropsType = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;
export type FormPropsType = DetailedHTMLProps<
  FormHTMLAttributes<HTMLFormElement>,
  HTMLFormElement
>;

type AuthProps = {
  type: "login" | "login-confirm" | "sign-up" | "sign-up-confirm";
};

/**
 * **refine** has a default auth page form which is served on `/login` route when the `authProvider` configuration is provided.
 * @param title is not implemented yet.
 * @see {@link https://refine.dev/docs/api-reference/core/components/auth-page/} for more details.
 */
export const AuthPage = (props: AuthProps) => {
  const { type } = props;
  const renderView = () => {
    switch (type) {
      case "sign-up":
        return <EnterEmailPage mode="sign-up" />;
      case "sign-up-confirm":
        return <ConfirmPage mode="sign-up" />;
      case "login-confirm":
        return <ConfirmPage mode="login" />;
      default:
        return <EnterEmailPage mode="login" />;
    }
  };

  return <>{renderView()}</>;
};
