import * as Sentry from "@sentry/react";
import { supabaseIntegration } from "@supabase/sentry-js-integration";
import { SupabaseClient } from "@supabase/supabase-js";

Sentry.init({
  environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
  dsn: "https://1049f2fc4e31bf90b28125d81728b164@o4507628016369664.ingest.de.sentry.io/4507628023578704",
  integrations: [
    supabaseIntegration(SupabaseClient, Sentry, {
      tracing: true,
      breadcrumbs: true,
      errors: true,
    }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    // staging
    /^https:\/\/jaojijujeijbjntphcbl\.supabase\.co\/rest/,
    // production
    /^https:\/\/wgmwtxfhoqqymmpqlviy\.supabase\.co\/rest/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
