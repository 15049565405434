import area from "@turf/area";
import turfBbox from "@turf/bbox";
import { convertArea, featureCollection } from "@turf/helpers";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const bbox = (geojson: any) => {
  // fixes typing difference between 2d and 3d bbox
  const [x1, y1, x2, y2] = turfBbox(geojson);

  return [x1, y1, x2, y2] satisfies [number, number, number, number];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const collectionBounds = <T extends any[]>(geojsons: T) => {
  const fc = featureCollection(geojsons);
  return bbox(fc);
};

export const hectaresFromGeojson = (geojson: Parameters<typeof area>[0]) => {
  return convertArea(area(geojson), "meters", "hectares");
};
