import { useNotification } from "@refinedev/core";
import { useQuery } from "@tanstack/react-query";
import { useAtomValue } from "jotai";

import { rubberBaselineLayerConfigAtom } from "@/components/map/state";
import type { Tables } from "@/db-types";
import { GEE_BUN_URL } from "@/env/env";
import { supabaseClient } from "@/utility";

type Plot = Tables<"plots">;

const fetchRubberBaseline = async (
  plotGeometry: GeoJSON.Geometry | undefined,
  notification: ReturnType<typeof useNotification>,
) => {
  const rubberUrl = `${GEE_BUN_URL}/rubber-baseline`;

  // Get the session from Supabase
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  // Get the access token
  const accessToken = session?.access_token;

  if (!accessToken) {
    notification.open?.({
      message: "No access token available",
      description: "Try again or logout and login again",
      type: "error",
    });
    throw new Error("No access token available");
  }

  const response = await fetch(rubberUrl, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({
      aoi: plotGeometry,
    }),
  });

  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json() as Promise<{
    url: string;
    coverageStats: { percentage: number };
  }>;
};

export const useRubberBaselineQuery = (currentPlot: Plot | undefined) => {
  const rubberBaselineLayerConfig = useAtomValue(rubberBaselineLayerConfigAtom);
  const x = useNotification();

  return useQuery({
    queryKey: ["rubber-baseline", currentPlot?.id],
    queryFn: () => fetchRubberBaseline(currentPlot?.json.geometry, x),
    enabled: Boolean(currentPlot && rubberBaselineLayerConfig.isEnabled),
    refetchOnWindowFocus: false,
  });
};
