import { DocumentTitleHandler } from "@refinedev/react-router-v6";

type HandlerType = React.ComponentProps<typeof DocumentTitleHandler>["handler"];

const customTitleHandler: HandlerType = () => {
  return "dilify";
};

// Usage
export const DocumentTitle = () => (
  <DocumentTitleHandler handler={customTitleHandler} />
);
