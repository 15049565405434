import { useCreate } from "@refinedev/core";
import { File, FileDown, UploadCloud } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
// import { SupportedGeoJSONSchema } from "@/utility/geojson";
import { useIdentity } from "@/utility/user";

const readFileAsText = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => resolve(e.target?.result as string);
    reader.onerror = (e) => reject(e);
    reader.readAsText(file);
  });
};

const getName = (
  properties: GeoJSON.Feature["properties"],
  fallback: string,
  number: number | undefined,
) => {
  const placeName =
    properties?.ProductionPlace ||
    properties?.name ||
    properties?.Name ||
    fallback;

  if (number) {
    return `${placeName} ${number}`;
  }

  return placeName;
};

export const ImportButton = () => {
  const { data: user } = useIdentity();
  const [file, setFile] = useState<File | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { mutate } = useCreate();

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles && acceptedFiles[0]) {
      setFile(acceptedFiles[0]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "application/json": [".json", ".geojson"],
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    multiple: false,
  });

  const handleUpload = async () => {
    if (!file) {
      return;
    }

    try {
      const fileContent = await readFileAsText(file);
      const parsedContent = JSON.parse(fileContent);
      const geojson = parsedContent as
        | GeoJSON.FeatureCollection
        | GeoJSON.Feature;

      // const result = SupportedGeoJSONSchema.safeParse(parsedContent);

      // if (!result.success) {
      //   console.log(result.error);
      //   return setError(
      //     "Processing error, file is not in valid GeoJSON format",
      //   );
      // }

      // const geojson = result.data;

      const fallbackName = file.name.split(".").slice(0, -1).join(".");

      const create = (feature: GeoJSON.Feature, index?: number) => {
        mutate({
          resource: "plots",
          values: {
            name: getName(feature.properties, fallbackName, index),
            json: feature,
            user_id: user!.id,
          },
        });
      };

      if (geojson.type === "FeatureCollection") {
        geojson.features.forEach((feature, index) => {
          create(feature, index + 1);
        });
      } else {
        create(geojson);
      }

      setIsOpen(false);
    } catch {
      setError("Processing failed, check file and try again");
    }
  };

  useEffect(() => {
    if (!isOpen) {
      const resetState = () => {
        setFile(null);
        setError(null);
      };
      resetState();
    }
  }, [isOpen]);

  const handleOpenChange = (isOpenNext: boolean) => {
    setIsOpen(isOpenNext);
  };

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        <Button variant="outline">Import</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Import Plot Data</DialogTitle>
          <DialogDescription>
            Upload a file containing plot data to import.
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="flex w-full items-center justify-center">
            {file ? (
              <div className="flex h-64 w-full flex-col items-center justify-center rounded-lg border-2 border-dashed border-stone-300 bg-stone-50 p-2">
                <File className="h-10 w-10 text-stone-500" />
                <p className="mt-2 line-clamp-2 overflow-hidden overflow-ellipsis text-center text-sm text-stone-500">
                  Selected file:{" "}
                  <span className="font-semibold">{file.name}</span>
                </p>
                <p className="mt-2 text-xs text-stone-400">
                  Click Upload to import the file
                </p>
              </div>
            ) : (
              <div
                {...getRootProps()}
                className={`flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed transition-colors duration-200 ease-in-out
                  ${
                    isDragActive
                      ? "border-blue-500 bg-blue-50"
                      : "border-stone-300 bg-stone-50 hover:bg-stone-100"
                  }`}
              >
                <input {...getInputProps()} />
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  {isDragActive ? (
                    <FileDown className="h-10 w-10 text-blue-500" />
                  ) : (
                    <UploadCloud className="h-10 w-10 text-stone-500" />
                  )}
                  <p
                    className={`mb-2 mt-2 text-sm ${
                      isDragActive ? "text-blue-600" : "text-stone-500"
                    }`}
                  >
                    {isDragActive ? (
                      "Drop the file here"
                    ) : (
                      <>
                        <span className="font-semibold">Drag and drop</span> or
                        click to upload
                      </>
                    )}
                  </p>
                  <p
                    className={`text-xs ${
                      isDragActive ? "text-blue-400" : "text-stone-500"
                    }`}
                  >
                    GeoJSON or JSON (MAX. 10MB)
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <Button onClick={handleUpload} disabled={!file}>
            Upload
          </Button>
          {error && <p className="text-xs text-red-500">{error}</p>}
        </div>
      </DialogContent>
    </Dialog>
  );
};

// better geojson approach
// - should be valid geojson as first check (instead of combining geojson and polygon/point checks)
// - should be a polygon or a point as separate check with separate UI error message
// - also support MultiPoint, MultiPolygon, GeometryCollection
// - also check that polygons are closed
