import {
  ArrowLeft,
  CircleAlert,
  CircleCheck,
  Clock8,
  LucideEye,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { trades } from "./data";
import { RiskBadge } from "./risk-badge";

const StatusBar = ({ risk }: { risk: string }) => {
  const status = {
    "Deforestation risk": {
      title: "Deforestation risk detected",
      theme: "danger",
    },
    "Low risk": { title: "Checks complete. Low risk", theme: "success" },
    "Missing information": { title: "Missing information", theme: "warning" },
    "Invalid plot": { title: "Invalid plot", theme: "warning" },
    "Legal risk": { title: "Legal risk detected", theme: "danger" },
  }[risk] || { title: "blank", theme: "warning" };

  return (
    <Alert
      className={
        "items-center " +
        (status.theme === "danger"
          ? "border-red-300 bg-red-100"
          : status.theme === "warning"
            ? "border-yellow-300 bg-yellow-100"
            : "border-green-300 bg-green-100")
      }
    >
      <CircleAlert
        className="h-5 w-5"
        color={
          status.theme === "danger"
            ? "crimson"
            : status.theme === "warning"
              ? "#FFC109"
              : "green"
        }
      />
      <AlertTitle
        className={
          status.theme === "danger"
            ? "text-red-900"
            : status.theme === "warning"
              ? "text-yellow-900"
              : "text-green-900"
        }
      >
        {status.title}
      </AlertTitle>
    </Alert>
  );
};

export const TradeShow = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const trade = trades.find((trade) => trade.id === Number(id));

  if (!trade) {
    return <div>Trade not found</div>;
  }

  return (
    <div className="container mx-auto max-w-3xl p-8">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-extrabold tracking-tight">
          Trade {trade.id}
        </h1>
        <Button
          onClick={() => navigate("/trades")}
          variant="outline"
          className="flex items-center"
        >
          <ArrowLeft className="mr-2 h-4 w-4" />
          Back to Trades
        </Button>
      </div>
      <div className="grid gap-8">
        <StatusBar risk={trade.risk} />

        <div className="grid grid-cols-2">
          <div className="grid grid-rows-3 gap-4 text-stone-800">
            <div>
              <h3 className="font-semibold">Date</h3>
              <p>{trade.date}</p>
            </div>
            <div>
              <h3 className="font-semibold">Product</h3>
              <p>{trade.product}</p>
            </div>
            <div>
              <h3 className="font-semibold">Weight</h3>
              <p>
                {trade.risk === "Missing information"
                  ? "Unknown"
                  : trade.weight + " kg"}
              </p>
            </div>
          </div>
          <div>
            <h2 className="mb-2 text-lg font-semibold">Plot Checks</h2>
            <Card className="p-4">
              <div className="space-y-4">
                <div className="flex items-center gap-2">
                  {trade.risk === "Invalid plot" ? (
                    <CircleAlert className="h-6 w-6" color="#FFC109" />
                  ) : (
                    <CircleCheck className="h-6 w-6" color="green" />
                  )}
                  <span>Site validity</span>
                </div>
                <div className="flex items-center gap-2">
                  {trade.risk === "Deforestation risk" ? (
                    <CircleAlert className="h-6 w-6" color="crimson" />
                  ) : (
                    <CircleCheck className="h-6 w-6" color="green" />
                  )}
                  <span>Deforestation-free</span>
                </div>
                <div className="flex items-center gap-2">
                  <Clock8 className="h-6 w-6" color="#A1AAA1" />
                  <span>Legally produced</span>
                </div>
              </div>
            </Card>
          </div>
        </div>

        <div>
          <h2 className="mb-2 text-lg font-semibold">Raw Materials</h2>
          <Card>
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>ID</TableHead>
                  <TableHead>Date</TableHead>
                  <TableHead>Risk</TableHead>
                  <TableHead>Actions</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {trade.rawMaterials.map((supply) => (
                  <TableRow key={supply.id}>
                    <TableCell>{supply.id}</TableCell>
                    <TableCell>{supply.date}</TableCell>
                    <TableCell>
                      <RiskBadge risk={supply.risk} />
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="ghost"
                        onClick={() => navigate(`/supply/${supply.id}`)}
                      >
                        <LucideEye size={16} />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        </div>
      </div>
    </div>
  );
};
