import { LocateFixed, ZoomIn } from "lucide-react";
import { useEffect, useState } from "react";
import { useMap } from "react-map-gl";

import { Card } from "@/components/ui/card";

export const MapInfo = () => {
  const { current: map } = useMap();
  const [mapInfo, setMapInfo] = useState({ zoom: 0, lat: 0, lng: 0 });

  useEffect(() => {
    if (!map) {
      return;
    }

    const onMove = () => {
      const { lng, lat } = map.getCenter();
      setMapInfo({
        zoom: map.getZoom(),
        lng,
        lat,
      });
    };

    map.on("move", onMove);
    onMove(); // Set initial values

    return () => {
      map.off("move", onMove);
    };
  }, [map]);

  return (
    <Card className="absolute right-12 top-2.5 z-10 rounded-sm p-1">
      <div className="flex items-center space-x-1 text-xs">
        <div className="rounded-xs flex items-center rounded-sm bg-neutral-100 p-2">
          <ZoomIn className="mr-1 h-4 w-4" />
          <span>{mapInfo.zoom.toFixed(1)}</span>
        </div>
        <div className="rounded-xs flex items-center rounded-sm bg-neutral-100 p-2">
          <LocateFixed className="mr-1 h-4 w-4" />
          <span>
            {mapInfo.lng.toFixed(4)}°, {mapInfo.lat.toFixed(4)}°
          </span>
        </div>
      </div>
    </Card>
  );
};
