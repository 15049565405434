import { atomWithStorage } from "jotai/utils";

export const eugfcLayerConfigAtom = atomWithStorage("eugfcLayerConfig", {
  isEnabled: false,
  opacity: 70,
});

export const rubberBaselineLayerConfigAtom = atomWithStorage(
  "rubberBaselineLayerConfig",
  {
    isEnabled: false,
    opacity: 70,
  },
);
