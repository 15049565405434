import "./App.css";
import "./utility/sentry";

import { Authenticated, Refine } from "@refinedev/core";
import { DevtoolsPanel, DevtoolsProvider } from "@refinedev/devtools";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";
import routerBindings, {
  CatchAllNavigate,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { liveProvider } from "@refinedev/supabase";
import * as Sentry from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Analytics } from "@vercel/analytics/react";
import { posthog } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React from "react";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";

import authProvider from "./authProvider";
import { AuthPage } from "./components/auth/auth";
import { LoginLayout } from "./components/auth/login";
import { DocumentTitle } from "./components/document-title";
import { Layout } from "./components/layout";
import { MapLayout } from "./components/map/map";
import { Toaster } from "./components/ui/sonner";
import { TooltipProvider } from "./components/ui/tooltip";
import { POSTHOG_API_HOST, POSTHOG_KEY, POSTHOG_UI_HOST } from "./env/env";
import { PlotCreate } from "./pages/plots/create";
import { PlotEdit } from "./pages/plots/edit";
import { PlotList } from "./pages/plots/list";
import { PlotShow } from "./pages/plots/show";
import { SettingsPage } from "./pages/settings";
import { SupplyShow } from "./pages/supply/show";
import { TradeList } from "./pages/trades/list";
import { TradeShow } from "./pages/trades/show";
import { dataProvider } from "./providers/data-provider";
import { notificationProvider } from "./providers/notifications";
import { supabaseClient } from "./utility";

posthog.init(POSTHOG_KEY, {
  api_host: POSTHOG_API_HOST,
  ui_host: POSTHOG_UI_HOST,
  person_profiles: "identified_only",
});

const queryClient = new QueryClient();

function App() {
  return (
    <React.StrictMode>
      <PostHogProvider client={posthog}>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
          <BrowserRouter>
            <TooltipProvider>
              <RefineKbarProvider>
                <DevtoolsProvider>
                  <QueryClientProvider client={queryClient}>
                    <Refine
                      dataProvider={dataProvider(supabaseClient)}
                      liveProvider={liveProvider(supabaseClient)}
                      authProvider={authProvider}
                      routerProvider={routerBindings}
                      notificationProvider={notificationProvider}
                      resources={[
                        {
                          name: "plots",
                          list: "/plots",
                          create: "/plots/create",
                          edit: "/plots/edit/:id",
                          show: "/plots/show/:id",
                          meta: {
                            canDelete: true,
                          },
                        },
                        {
                          name: "trades",
                          list: "/trades",
                          show: "/trades/show/:id",
                        },
                      ]}
                      options={{
                        syncWithLocation: true,
                        warnWhenUnsavedChanges: false, // TODO - test reenabling this
                        useNewQueryKeys: true,
                        projectId: "rUbZJX-V3pdaW-bcjYEu",
                      }}
                    >
                      <Routes>
                        <Route
                          element={
                            <Authenticated
                              key="authenticated-routes"
                              fallback={<CatchAllNavigate to="/login" />}
                            >
                              <Layout>
                                <Outlet />
                              </Layout>
                            </Authenticated>
                          }
                        >
                          <Route
                            index
                            element={<NavigateToResource resource="plots" />}
                          />

                          <Route
                            path="/plots"
                            element={
                              <MapLayout>
                                <Outlet />
                              </MapLayout>
                            }
                          >
                            <Route index element={<PlotList />} />
                            <Route path="create" element={<PlotCreate />} />
                            <Route path="edit/:id" element={<PlotEdit />} />
                            <Route path="show/:id" element={<PlotShow />} />
                          </Route>

                          <Route path="/trades" element={<Outlet />}>
                            <Route index element={<TradeList />} />
                            <Route path="show/:id" element={<TradeShow />} />
                          </Route>

                          <Route path="/supply" element={<Outlet />}>
                            <Route
                              path="/supply/:id"
                              element={<SupplyShow />}
                            />
                          </Route>

                          <Route path="/suppliers">
                            <Route
                              index
                              element={
                                <div>SUPPLIERS page under construction</div>
                              }
                            />
                          </Route>

                          <Route path="/settings">
                            <Route index element={<SettingsPage />} />
                          </Route>
                        </Route>

                        <Route
                          element={
                            <Authenticated
                              key="auth-pages"
                              fallback={
                                <LoginLayout>
                                  <Outlet />
                                </LoginLayout>
                              }
                            >
                              <NavigateToResource />
                            </Authenticated>
                          }
                        >
                          <Route
                            path="/login"
                            element={<AuthPage type="login" />}
                          />
                          <Route
                            path="/login/confirm"
                            element={<AuthPage type="login-confirm" />}
                          />
                          {/* 
                  Sign up temporarily disabled during pilot period
                  <Route
                    path="/sign-up"
                    element={<AuthPage type="sign-up" />}
                  />
                  <Route
                    path="/sign-up/confirm"
                    element={<AuthPage type="sign-up-confirm" />}
                  />*/}
                        </Route>

                        <Route
                          element={
                            <Authenticated key="catch-all">
                              <Layout>
                                <Outlet />
                              </Layout>
                            </Authenticated>
                          }
                        >
                          <Route path="*" element={<div>404</div>} />
                        </Route>
                      </Routes>
                      <Toaster />
                      <RefineKbar />
                      <UnsavedChangesNotifier />
                      <DocumentTitle />
                    </Refine>
                  </QueryClientProvider>
                  <DevtoolsPanel />
                </DevtoolsProvider>
              </RefineKbarProvider>
            </TooltipProvider>
            <Analytics />
          </BrowserRouter>
        </Sentry.ErrorBoundary>
      </PostHogProvider>
    </React.StrictMode>
  );
}

export default App;
