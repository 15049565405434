import type { AuthProvider } from "@refinedev/core";

import { supabaseClient } from "./utility";

export type LoginProps = {
  email: string;
  otp: string;
};

const authProvider: AuthProvider = {
  login: async ({ email, otp }: LoginProps) => {
    const { data, error } = await supabaseClient.auth.verifyOtp({
      email,
      token: otp,
      type: "email",
    });

    if (error) {
      return {
        success: false,
        error: {
          name: error.message || "Invalid OTP",
          message: error.cause ? String(error.cause) : "Login failed",
        },
      };
    }

    if (data) {
      return {
        success: true,
        redirectTo: "/",
      };
    }

    return {
      success: false,
      error: {
        message: "Login failed",
        name: "Invalid OTP",
      },
    };
  },
  logout: async () => {
    const { error } = await supabaseClient.auth.signOut();

    if (error) {
      return {
        success: false,
        error: error || {
          message: "Logout failed",
          name: "Invalid OTP",
        },
      };
    }

    return {
      success: true,
      redirectTo: "/",
    };
  },
  onError: async (error) => {
    if (error?.code === "PGRST301" || error?.code === 401) {
      return {
        logout: true,
      };
    }

    return { error };
  },
  check: async () => {
    const { data, error } = await supabaseClient.auth.getSession();
    const { session } = data;

    if (!session) {
      return {
        authenticated: false,
        error: error || {
          message: "Check failed",
          name: "Session not found",
        },
        redirectTo: "/login",
      };
    }

    return {
      authenticated: true,
    };
  },
  getPermissions: async () => {
    const user = await supabaseClient.auth.getUser();

    if (user) {
      return user.data.user?.role;
    }

    return null;
  },
  getIdentity: async () => {
    const { data } = await supabaseClient.auth.getUser();

    if (data?.user) {
      return {
        ...data.user,
        name: data.user.email,
      };
    }

    return null;
  },
};

export default authProvider;
