import type { DataProvider } from "@refinedev/core";
import { dataProvider as createDataProvider } from "@refinedev/supabase";
import type { SupabaseClient } from "@supabase/supabase-js";
import { FunctionsError } from "@supabase/supabase-js";

import type { Database } from "@/db-types";

const handleEdgeFunctionError = async (error: unknown) => {
  if (error instanceof FunctionsError && error.context instanceof Response) {
    const message = (await error.context.json()).error;
    const statusCode = error.context?.status;

    return Promise.reject({
      ...error,
      message,
      statusCode,
    });
  }

  return Promise.reject(error);
};

export const dataProvider = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  supabaseClient: SupabaseClient<Database, "public", any>,
): Required<DataProvider> => {
  const defaultDataProvider = createDataProvider(supabaseClient);
  return {
    ...defaultDataProvider,
    create: async (props) => {
      const { resource } = props;

      if (resource === "plots") {
        const { data, error } = await supabaseClient.functions.invoke(
          "create-plot",
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          { body: props.variables as Record<string, any> },
        );

        if (error) {
          return await handleEdgeFunctionError(error);
        }

        return { data: data[0] };
      }

      return defaultDataProvider.create(props);
    },
  };
};
