import { useLogout, useWarnAboutChange } from "@refinedev/core";
import { ArrowRightLeft, LogOut, MapPinned, Settings } from "lucide-react";
import { useLocation } from "react-router-dom";
import { twJoin } from "tailwind-merge";

import { Button } from "./ui/button";
import type { LinkProps } from "./ui/link";
import { Link } from "./ui/link";
import { Tooltip, TooltipContent, TooltipTrigger } from "./ui/tooltip";

type AppRailLinkProps = {
  icon: React.ReactNode;
  title: string;
  isActive?: boolean;
  to: LinkProps["to"];
};

import { useFeatureFlagEnabled } from "posthog-js/react";

const appRailLinkSharedClasses =
  "relative flex h-9 w-9 items-center justify-center rounded-lg text-white transition-colors hover:text-[#ADFF00] md:h-8 md:w-8";

const AppRailLink = ({ icon, title, isActive, to }: AppRailLinkProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Link
          to={to}
          className={twJoin(
            appRailLinkSharedClasses,
            isActive &&
              "after:[''] after:absolute after:-right-0.5 after:h-1.5 after:w-1.5 after:rounded-full after:bg-[#ADFF00]",
          )}
        >
          {icon}
          <span className="sr-only">{title}</span>
        </Link>
      </TooltipTrigger>
      <TooltipContent side="right">{title}</TooltipContent>
    </Tooltip>
  );
};

type AppRailButtonProps = {
  icon: React.ReactNode;
  title: string;
  onClick?: () => void;
};

const AppRailButton = ({ icon, title, onClick }: AppRailButtonProps) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Button
          variant="link"
          onClick={onClick}
          className={twJoin(appRailLinkSharedClasses, "p-0")}
        >
          {icon}
          <span className="sr-only">{title}</span>
        </Button>
      </TooltipTrigger>
      <TooltipContent side="right">{title}</TooltipContent>
    </Tooltip>
  );
};

export const AppRail = () => {
  const isTradesEnabled = useFeatureFlagEnabled("demo-only");

  const { warnWhen, setWarnWhen } = useWarnAboutChange();
  const { mutate: mutateLogout } = useLogout();
  const { pathname } = useLocation();
  const handleLogout = () => {
    if (warnWhen) {
      const confirm = window.confirm(
        "Are you sure you want to leave? You have unsaved changes.",
      );

      if (confirm) {
        setWarnWhen(false);
        mutateLogout();
      }
    } else {
      mutateLogout();
    }
  };

  return (
    <aside className="fixed inset-y-0 left-0 z-10 flex w-14 flex-col border-r bg-[#002B24] ">
      <nav className="flex flex-col items-center gap-4 px-2 py-3">
        {/* <Link
          to="/"
          className="group flex h-9 w-9 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-base font-semibold text-primary-foreground md:h-8 md:w-8"
        >
          <span>D.</span>
          <span className="sr-only">Dilify</span>
        </Link> */}
        <AppRailLink
          icon={<MapPinned className="h-5 w-5" />}
          title="Plots"
          to="/plots"
          isActive={pathname.startsWith("/plots")}
        />
        {/* <AppRailLink
          icon={<Handshake className="h-5 w-5" />}
          title="Suppliers"
          to="/suppliers"
          isActive={pathname === "/suppliers"}
        /> */}
        {isTradesEnabled && (
          <AppRailLink
            icon={<ArrowRightLeft className="h-5 w-5" />}
            title="Trades"
            to="/trades"
            isActive={pathname.startsWith("/trades")}
          />
        )}
      </nav>
      <nav className="mt-auto flex flex-col items-center gap-4 px-2 py-5">
        <AppRailLink
          icon={<Settings className="h-5 w-5" />}
          title="Settings"
          to="/settings"
          isActive={pathname === "/settings"}
        />
        <AppRailButton
          icon={<LogOut className="h-5 w-5" />}
          title="Log Out"
          onClick={handleLogout}
        />
      </nav>
    </aside>
  );
};
