import type { NotificationProvider } from "@refinedev/core";
import { toast } from "sonner";

export const notificationProvider = {
  open: ({ key, message, type, description }) => {
    const options = {
      id: key,
      description: description,
    };
    if (type === "error") {
      toast.error(message, options);
    } else if (type === "success") {
      toast.success(message, options);
    } else {
      toast(message, options);
    }
  },
  close: (key) => toast.dismiss(key),
} satisfies NotificationProvider;
