import { Badge } from "@/components/ui/badge";

export const RiskBadge = ({ risk }: { risk: string }) => {
  return (
    <Badge
      variant={
        risk === "Low risk"
          ? "success"
          : risk === "Deforestation risk" || risk === "Legal risk"
            ? "error"
            : "warn"
      }
    >
      {risk}
    </Badge>
  );
};
