import { useDeleteButton } from "@refinedev/core";

import {
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from "@/components/ui/alert-dialog";

export { AlertDialog as DeleteDialog } from "@/components/ui/alert-dialog";
export { AlertDialogTrigger as DeleteDialogTrigger } from "@/components/ui/alert-dialog";

export const DeleteDialogContent = ({
  resource,
  id,
  name,
}: {
  resource: string;
  id: string;
  name: string;
}) => {
  const { onConfirm, confirmOkLabel, cancelLabel } = useDeleteButton({
    resource,
    id,
  });

  return (
    <AlertDialogContent
      aria-modal
      onClick={(e) => {
        // this prevents clicks on the modal from triggering navigation on the parent
        e.stopPropagation();
      }}
    >
      <AlertDialogHeader>
        <AlertDialogTitle>Delete plot confirmation</AlertDialogTitle>
        <AlertDialogDescription>
          Are sure you want to delete <strong>{name}</strong>? <br />
          This action cannot be reversed.
        </AlertDialogDescription>
      </AlertDialogHeader>
      <AlertDialogFooter>
        <AlertDialogCancel>{cancelLabel}</AlertDialogCancel>
        <AlertDialogAction onClick={onConfirm}>
          {confirmOkLabel}
        </AlertDialogAction>
      </AlertDialogFooter>
    </AlertDialogContent>
  );
};
