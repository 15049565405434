import { ChevronRight, CircleAlert, CircleCheck, Mail } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

import { Alert, AlertTitle } from "@/components/ui/alert";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Table, TableBody, TableCell, TableRow } from "@/components/ui/table";

import { trades } from "../trades/data";

const DeforestationRiskAlert = () => {
  return (
    <Alert className="items-center border-red-300 bg-red-100">
      <CircleAlert className="h-5 w-5" color="crimson" />
      <AlertTitle className="text-red-900">Deforestation risk</AlertTitle>
    </Alert>
  );
};

const MissingInformationAlert = () => {
  return (
    <Alert className="items-center border-yellow-300 bg-yellow-100">
      <CircleAlert className="h-5 w-5" color="#FFC109" />
      <AlertTitle className="text-yellow-900">Missing information</AlertTitle>
    </Alert>
  );
};

const LegalRiskAlert = () => {
  return (
    <Alert className="items-center border-red-300 bg-red-100">
      <CircleAlert className="h-5 w-5" color="crimson" />
      <AlertTitle className="text-red-900">Legal risk</AlertTitle>
    </Alert>
  );
};

const OkRow = () => {
  const navigate = useNavigate();
  return (
    <TableRow>
      <TableCell className="flex items-center gap-2">
        <CircleCheck className="h-5 w-5 text-green-600" />
        {`Plot ${Math.floor(Math.random() * 100) + 1}`}
      </TableCell>
      <TableCell>Check complete</TableCell>
      <TableCell>{Math.floor(Math.random() * 100) + 10} ha</TableCell>
      <TableCell>
        <ChevronRight
          className="ml-auto h-5 w-5"
          onClick={() => {
            navigate(`/plots`);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const DeforestationRiskRow = () => {
  const navigate = useNavigate();
  return (
    <TableRow>
      <TableCell className="flex items-center gap-2">
        <CircleAlert className="h-5 w-5 text-red-600" />
        {`Plot ${Math.floor(Math.random() * 100) + 1}`}
      </TableCell>
      <TableCell>Deforestation risk detected</TableCell>
      <TableCell>{Math.floor(Math.random() * 100) + 10} ha</TableCell>
      <TableCell>
        <ChevronRight
          className="ml-auto h-5 w-5"
          onClick={() => {
            navigate(`/plots`);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const InvalidPlotRow = () => {
  const navigate = useNavigate();
  return (
    <TableRow>
      <TableCell className="flex items-center gap-2">
        <CircleAlert className="h-5 w-5 text-amber-600" />
        {`Plot ${Math.floor(Math.random() * 100) + 1}`}
      </TableCell>
      <TableCell>Invalid plot</TableCell>
      <TableCell>{Math.floor(Math.random() * 100) + 10} ha</TableCell>
      <TableCell>
        <ChevronRight
          className="ml-auto h-5 w-5"
          onClick={() => {
            navigate(`/plots`);
          }}
        />
      </TableCell>
    </TableRow>
  );
};

const supplies = trades.map((trade) => trade.rawMaterials).flat();

export const SupplyShow = () => {
  const { id } = useParams();
  const supply = supplies.find((supply) => supply.id === Number(id));

  if (!supply) {
    return <div>Supply not found</div>;
  }

  return (
    <div className="container mx-auto max-w-3xl space-y-6 p-8">
      <div className="mb-4 flex items-center justify-between">
        <h1 className="text-2xl font-extrabold tracking-tight">
          Raw Materials Shipment {supply.id}
        </h1>
      </div>
      {supply.risk === "Deforestation risk" && <DeforestationRiskAlert />}
      {supply.risk === "Missing information" && <MissingInformationAlert />}
      {supply.risk === "Legal risk" && <LegalRiskAlert />}

      <div className="flex gap-12">
        <div>
          <h3 className="font-semibold">Date</h3>
          <p>{supply.date}</p>
        </div>
        <div>
          <h3 className="font-semibold">Weight</h3>
          <p>
            {supply.risk === "Missing information"
              ? "Unknown"
              : Math.floor(Math.random()) + 10 + " kg"}
          </p>
        </div>
      </div>

      <div className="mt-4">
        <h2 className="mb-2 text-lg font-semibold">Plots</h2>
        <Card>
          <Table>
            <TableBody>
              {supply.risk === "Deforestation risk" && <DeforestationRiskRow />}
              {supply.risk === "Invalid plot" && <InvalidPlotRow />}
              <OkRow />
              <OkRow />
              <OkRow />
              <OkRow />
            </TableBody>
          </Table>
        </Card>
      </div>
      {supply.risk !== "Low risk" && (
        <div className="mt-4">
          <h2 className="mb-2 text-lg font-semibold">Actions</h2>
          <div className="flex gap-2">
            {supply.risk === "Deforestation risk" && (
              <Button variant="default" className="flex items-center">
                <Mail className="mr-2 h-4 w-4" />
                Notify supplier
              </Button>
            )}

            {supply.risk === "Invalid plot" && (
              <Button variant="default" className="flex items-center">
                <Mail className="mr-2 h-4 w-4" />
                Notify supplier
              </Button>
            )}

            {supply.risk === "Legal risk" && (
              <Button variant="default" className="flex items-center">
                <Mail className="mr-2 h-4 w-4" />
                Notify supplier
              </Button>
            )}

            {supply.risk === "Missing information" && (
              <Button variant="default" className="flex items-center">
                <Mail className="mr-2 h-4 w-4" />
                Request supplier data
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
