import { useAtomValue } from "jotai";
import type { MapEventType } from "mapbox-gl";
import { useEffect, useMemo, useState } from "react";
import { Layer, Source, useMap } from "react-map-gl";

import type { Tables } from "@/db-types";
import { useRubberBaselineQuery } from "@/queries/rubber-baseline";

import { rubberBaselineLayerConfigAtom } from "./state";

type Plot = Tables<"plots">;

type RubberBaselineProps = {
  currentPlot: Plot;
};

export const RubberBaseline = ({ currentPlot }: RubberBaselineProps) => {
  const rubberBaselineLayerConfig = useAtomValue(rubberBaselineLayerConfigAtom);

  const { data, error, isLoading } = useRubberBaselineQuery(currentPlot);

  useEffect(() => {
    if (error) {
      console.error("Error fetching rubber-baseline data:", error);
    }
  }, [error]);

  const tilesUrl = data?.url;
  const tiles = useMemo<[string] | undefined>(() => {
    return tilesUrl ? [tilesUrl] : undefined;
  }, [tilesUrl]);

  const [isRasterLoading, setIsRasterLoading] = useState(true);
  const [loadingOpacity, setLoadingOpacity] = useState(0.5);
  const { current: map } = useMap();

  useEffect(() => {
    if (!map) {
      return;
    }

    const handleSourceData = (e: MapEventType["sourcedata"]) => {
      if (e.isSourceLoaded && e.sourceId === "gee-source") {
        setIsRasterLoading(false);
      }
    };

    map.on("sourcedata", handleSourceData);

    return () => {
      map.off("sourcedata", handleSourceData);
    };
  }, [map]);

  // Animation effect for shimmering
  useEffect(() => {
    if (!map) {
      return;
    }

    let animationFrameId: number;
    let start: number;

    const animate = (timestamp: number) => {
      if (!start) {
        start = timestamp;
      }
      const progress = (timestamp - start) % 2000; // 2-second loop
      const opacity = 0.2 + 0.2 * Math.sin((progress / 2000) * 2 * Math.PI);
      setLoadingOpacity(opacity);
      animationFrameId = requestAnimationFrame(animate);
    };

    animationFrameId = requestAnimationFrame(animate);

    return () => {
      cancelAnimationFrame(animationFrameId);
    };
  }, [map]);

  if (!rubberBaselineLayerConfig.isEnabled) {
    return null;
  }

  return (
    <>
      {tiles && (
        <Source id="gee-source" type="raster" tiles={tiles} tileSize={256}>
          <Layer
            id="gee-layer"
            type="raster"
            paint={{
              "raster-opacity": rubberBaselineLayerConfig.opacity / 100,
              "raster-color": "#D96DFF",
            }}
          />
        </Source>
      )}
      {(isLoading || isRasterLoading) && (
        <Source id="aoi-source" type="geojson" data={currentPlot.json}>
          <Layer
            id="loading-layer"
            type="fill"
            paint={{ "fill-color": "#A6FF00", "fill-opacity": loadingOpacity }}
          />
        </Source>
      )}
    </>
  );
};
