export const trades = [
  {
    id: 15467,
    date: "20 Jan 2025",
    product: "Tires",
    weight: "50kg",
    risk: "Deforestation risk",
    rawMaterials: [
      {
        id: 15000,
        date: "20 Jan 2025",
        risk: "Deforestation risk",
      },
      ...Array.from({ length: 10 }, (_, i) => ({
        id: 15001 + i,
        date: `${15 + i} Jan 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 15472,
    date: "23 Jan 2025",
    product: "Rubber compound",
    weight: "44kg",
    risk: "Low risk",
    rawMaterials: [
      {
        id: 15020,
        date: "23 Jan 2025",
        risk: "Low risk",
      },
      ...Array.from({ length: 10 }, (_, i) => ({
        id: 15021 + i,
        date: `${18 + i} Jan 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 15711,
    date: "29 Jan 2025",
    product: "Latex",
    weight: "28kg",
    risk: "Missing information",
    rawMaterials: [
      {
        id: 15040,
        date: "29 Jan 2025",
        risk: "Missing information",
      },
      ...Array.from({ length: 10 }, (_, i) => ({
        id: 15041 + i,
        date: `${24 + i} Jan 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 15831,
    date: "06 Feb 2025",
    product: "Tires",
    weight: "55kg",
    risk: "Invalid plot",
    rawMaterials: [
      {
        id: 15060,
        date: "1 Feb 2025",
        risk: "Invalid plot",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15061 + i,
        date: `${2 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 16019,
    date: "05 Feb 2025",
    product: "Rubber sheets",
    weight: "60kg",
    risk: "Low risk",
    rawMaterials: [
      {
        id: 15080,
        date: "31 Jan 2025",
        risk: "Low risk",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15081 + i,
        date: `${1 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 16206,
    date: "08 Feb 2025",
    product: "Tires",
    weight: "120kg",
    risk: "Legal risk",
    rawMaterials: [
      {
        id: 15100,
        date: "3 Feb 2025",
        risk: "Legal risk",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15101 + i,
        date: `${4 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 17892,
    date: "12 Feb 2025",
    product: "Latex",
    weight: "40kg",
    risk: "Low risk",
    rawMaterials: [
      {
        id: 15120,
        date: "7 Feb 2025",
        risk: "Low risk",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15121 + i,
        date: `${8 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 17988,
    date: "18 Feb 2025",
    product: "Rubber compound",
    weight: "50kg",
    risk: "Low risk",
    rawMaterials: [
      {
        id: 15140,
        date: "13 Feb 2025",
        risk: "Low risk",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15141 + i,
        date: `${14 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
  {
    id: 17994,
    date: "20 Feb 2025",
    product: "Rubber sheets",
    weight: "52kg",
    risk: "Missing information",
    rawMaterials: [
      {
        id: 15160,
        date: "15 Feb 2025",
        risk: "Missing information",
      },
      ...Array.from({ length: 9 }, (_, i) => ({
        id: 15161 + i,
        date: `${16 + i} Feb 2025`,
        risk: "Low risk",
      })),
    ],
  },
];
