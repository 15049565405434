import { useNavigation } from "@refinedev/core";
import { LucideEye } from "lucide-react";
import { useState } from "react";

import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";

import { ImportButton } from "./components/import-dialog";
import { trades } from "./data";
import { RiskBadge } from "./risk-badge";

export const TradeList = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const { show } = useNavigation();

  return (
    <div className="container mx-auto p-8">
      <div className="flex items-center justify-between">
        <h1 className="mb-4 text-2xl font-extrabold tracking-tight">Trades</h1>
        <ImportButton />
      </div>
      <div className="mb-4 flex w-72 gap-2">
        <Input
          type="text"
          placeholder="Search trades"
          className="flex-grow rounded-l border p-2"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Button>Search</Button>
      </div>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Trade ID</TableHead>
              <TableHead>Export Date</TableHead>
              <TableHead>Product</TableHead>
              <TableHead>Weight</TableHead>
              <TableHead>Risk</TableHead>
              <TableHead>Actions</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {trades.map((trade) => (
              <TableRow key={trade.id}>
                <TableCell className="font-bold">{trade.id}</TableCell>
                <TableCell>{trade.date}</TableCell>
                <TableCell>{trade.product}</TableCell>
                <TableCell>{trade.weight}</TableCell>
                <TableCell>
                  <RiskBadge risk={trade.risk} />
                </TableCell>
                <TableCell>
                  <div className="flex flex-row flex-nowrap gap-0">
                    <Button
                      variant="ghost"
                      size="icon"
                      onClick={() => {
                        show("trades", trade.id);
                      }}
                    >
                      <LucideEye size={16} />
                    </Button>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
};
