import { useNavigation } from "@refinedev/core";
import { ArrowLeft } from "lucide-react";

import { MapCard } from "@/components/map/map";
import { Button } from "@/components/ui/button";

export const PlotEdit = () => {
  const { list } = useNavigation();

  return (
    <MapCard>
      <div className="min-w-[320px] p-2">
        <div className="mx-2 my-2 flex items-center justify-between">
          <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight">
            Edit Plot
          </h1>
          <div className="p-2">
            <Button
              onClick={() => {
                list("plots");
              }}
              variant="secondary"
            >
              <ArrowLeft size={16} />
              List
            </Button>
          </div>
        </div>
      </div>
    </MapCard>
  );
};
