import { usePostHog } from "posthog-js/react";
import { type PropsWithChildren, useEffect } from "react";

import { useIdentity } from "@/utility/user";

import { AppRail } from "./app-rail";

const usePosthogIdentify = () => {
  const posthog = usePostHog();
  const { data: user } = useIdentity();
  const userId = user?.id;
  const userEmail = user?.email;

  useEffect(() => {
    if (userId && userEmail) {
      posthog?.identify(userId, {
        email: userEmail,
      });
    }
  }, [posthog, userId, userEmail]);
};

export const Layout: React.FC<PropsWithChildren> = ({ children }) => {
  usePosthogIdentify();

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <AppRail />
      <main className="flex h-screen flex-col gap-4 pl-14">{children}</main>
    </div>
  );
};
