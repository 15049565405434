import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useState } from "react";
import type { ControlPosition } from "react-map-gl";
import { useControl } from "react-map-gl";
import type { MapContextValue } from "react-map-gl/dist/esm/components/map";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Listener = (event?: any) => any;

export type Feature = GeoJSON.Feature<GeoJSON.Polygon | GeoJSON.Point> & {
  id: string;
};

export type OnCreate = (evt: { features: Feature[] }, draw: MapboxDraw) => void;
export type OnUpdate = (
  evt: { features: Feature[]; action: string },
  draw: MapboxDraw,
) => void;
export type OnDelete = (evt: { features: Feature[] }, draw: MapboxDraw) => void;

type DrawControlProps = ConstructorParameters<typeof MapboxDraw>[0] & {
  position?: ControlPosition;

  onCreate?: OnCreate;
  onUpdate?: OnUpdate;
  onDelete?: OnDelete;
};

export default function DrawControl(props: DrawControlProps) {
  const [draw] = useState(() => new MapboxDraw(props));

  const onDrawCreate: Listener =
    ((e) => props.onCreate?.(e, draw)) || (() => null);
  const onDrawUpdate: Listener =
    ((e) => props.onUpdate?.(e, draw)) || (() => null);
  const onDrawDelete: Listener =
    ((e) => props.onDelete?.(e, draw)) || (() => null);

  const onControlCreate = () => draw;
  const onControlAdd = ({ map }: MapContextValue) => {
    map.on("draw.create", onDrawCreate);
    map.on("draw.update", onDrawUpdate);
    map.on("draw.delete", onDrawDelete);
  };
  const onControlRemove = ({ map }: MapContextValue) => {
    map.off("draw.create", onDrawCreate);
    map.off("draw.update", onDrawUpdate);
    map.off("draw.delete", onDrawDelete);
  };
  const controlOptions = {
    position: props.position,
  };

  useControl<MapboxDraw>(
    onControlCreate,
    onControlAdd,
    onControlRemove,
    controlOptions,
  );

  return null;
}
