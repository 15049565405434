import { z } from "zod";

export const envSchema = z.object({
  VITE_SENTRY_ENVIRONMENT: z.string(),
  VITE_POSTHOG_KEY: z.string(),
  VITE_POSTHOG_API_HOST: z.string().url(),
  VITE_POSTHOG_UI_HOST: z.string().url(),
  VITE_SUPABASE_URL: z.string().url(),
  VITE_SUPABASE_KEY: z.string(),
  VITE_MAPBOX_TOKEN: z.string(),
  VITE_GEE_BUN_URL: z.string().url(),
});

export function validateEnv(env: Record<string, unknown>) {
  const { success, error, data } = envSchema.safeParse(env);

  if (!success) {
    console.error("Environment variable validation failed:", error);
    error.issues.forEach((issue) => {
      console.error(`- ${issue.path.join(".")}: ${issue.message}`);
    });
    throw new Error("Invalid environment variables");
  }

  return data;
}
