import { Layer, Source } from "react-map-gl";

const gridGeoJSON = (() => {
  const lines = [];
  for (let lon = -180; lon <= 180; lon += 10) {
    lines.push({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [
          [lon, -90],
          [lon, 90],
        ],
      },
    } satisfies GeoJSON.Feature);
  }
  for (let lat = -90; lat <= 90; lat += 10) {
    lines.push({
      type: "Feature",
      properties: {},
      geometry: {
        type: "LineString",
        coordinates: [
          [-180, lat],
          [180, lat],
        ],
      },
    } satisfies GeoJSON.Feature);
  }
  return {
    type: "FeatureCollection",
    features: lines,
  } satisfies GeoJSON.FeatureCollection;
})();

const Grid = () => {
  return (
    <Source id="grid-source" type="geojson" data={gridGeoJSON}>
      <Layer
        id="grid-layer"
        type="line"
        paint={{
          "line-color": "#ffffff",
          "line-opacity": 0.3,
        }}
      />
    </Source>
  );
};

export default Grid;
