import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";

import type { HttpError } from "@refinedev/core";
import { useList } from "@refinedev/core";
import { useAtomValue, useSetAtom } from "jotai";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import type {
  MapboxGeoJSONFeature,
  MapLayerMouseEvent,
  MapRef,
} from "react-map-gl";
import Mapbox, { Layer, Marker, NavigationControl, Source } from "react-map-gl";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";

import type { Tables } from "@/db-types";
import { currentFeatureAtom } from "@/pages/plots/state";
import { isTruthy } from "@/utility/typescript";

import { Card } from "../ui/card";
import type { OnCreate, OnDelete } from "./draw-control";
import DrawControl from "./draw-control";
import Grid from "./grid";
import { LayerControl } from "./layer-control";
import { MapInfo } from "./map-info";
import { RubberBaseline } from "./rubber-baseline";
import { eugfcLayerConfigAtom } from "./state";
import { bbox, collectionBounds } from "./utils";

const HIGHLIGHT_GREEN = "#ADFF00";

type Plot = Tables<"plots">;

const MAP_PADDING = 40;
const CARD_INSET = 12;

const fitBoundsOptions = (type: "list" | "show") => ({
  padding: {
    top: MAP_PADDING,
    bottom: MAP_PADDING,
    left: (type === "list" ? 500 : 380) + CARD_INSET + MAP_PADDING,
    right: MAP_PADDING,
  },
  maxZoom: 15.5,
});

export const MapElement = () => {
  const mapRef = useRef<MapRef>(null);

  const eugfcLayerConfig = useAtomValue(eugfcLayerConfigAtom);

  // DRAWING
  const [searchParams] = useSearchParams();
  const drawType = useMatch("/plots/create") && searchParams.get("type");
  const setCurrentFeature = useSetAtom(currentFeatureAtom);
  const onUpdate = useCallback<OnCreate>(
    (e) => {
      const currentFeature = e.features.at(-1);
      if (currentFeature) {
        setCurrentFeature(currentFeature);
      }
    },
    [setCurrentFeature],
  );
  const onDelete = useCallback<OnDelete>(
    (_, draw) => {
      draw.changeMode("draw_polygon");
      setCurrentFeature(null);
    },
    [setCurrentFeature],
  );

  // DATA QUERY
  const { data, isLoading, isError } = useList<Plot, HttpError>({
    resource: "plots",
    pagination: {
      mode: "off",
    },
  });
  const plots = data?.data;

  // CURRENT PLOT - FIT BOUNDS ETC
  // could/should move this to the page, and use the MapProvider
  const currentPlotId = useMatch("/plots/show/:id")?.params.id;
  const currentPlot = plots?.find((plot) => plot.id === currentPlotId);
  const currentPlotBounds = useMemo(() => {
    if (currentPlot) {
      return bbox(currentPlot.json);
    }
  }, [currentPlot]);
  useEffect(() => {
    if (currentPlotBounds) {
      mapRef.current?.fitBounds(currentPlotBounds, fitBoundsOptions("show"));
    }
  }, [currentPlotBounds]);

  // FIT BOUNDS LIST
  // could/should move this to the page, and use the MapProvider
  const allPlotsBounds = useMemo(
    () =>
      plots?.length ? collectionBounds(plots.map((v) => v.json)) : undefined,
    [plots],
  );
  const isListView = Boolean(useMatch("/plots"));
  useEffect(() => {
    if (allPlotsBounds && isListView) {
      mapRef.current?.fitBounds(allPlotsBounds, fitBoundsOptions("list"));
    }
  }, [isListView, allPlotsBounds]);

  // / HOVER INFO
  const interactiveLayerIds = useMemo(() => {
    return plots?.map((plot) => "polygon-fill-" + plot.id);
  }, [plots]);
  const [, setHoverInfo] = useState<{
    feature: MapboxGeoJSONFeature | undefined;
    x: number;
    y: number;
  } | null>(null);
  const handleHover = useCallback((event: MapLayerMouseEvent) => {
    const {
      features,
      point: { x, y },
    } = event;
    const hoveredFeature = features?.[0];

    if (hoveredFeature) {
      setHoverInfo({ feature: hoveredFeature, x, y });
    } else {
      setHoverInfo(null);
    }
  }, []);

  // CURSOR
  const [cursor, setCursor] = useState<string | undefined>(undefined);
  const onMouseEnter = useCallback(() => setCursor("pointer"), []);
  const onMouseLeave = useCallback(() => setCursor(undefined), []);

  // CLICK FEATURE
  const navigate = useNavigate();
  const handleClick = useCallback(
    (event: MapLayerMouseEvent) => {
      const clickedFeature = event.features?.[0];

      const featureId = clickedFeature?.source;
      if (featureId) {
        navigate("/plots/show/" + featureId);
      }
    },
    [navigate],
  );

  // / GUARDS
  if (isLoading) {
    return <div />;
  }
  if (isError) {
    return <div>Error...</div>;
  }

  const initialViewState = {
    zoom: 2,
    bounds: currentPlotBounds || allPlotsBounds,
    fitBoundsOptions: currentPlot
      ? fitBoundsOptions("show")
      : fitBoundsOptions("list"),
  };

  return (
    <Mapbox
      ref={mapRef}
      reuseMaps
      mapLib={import("mapbox-gl")}
      mapboxAccessToken={import.meta.env.VITE_MAPBOX_TOKEN}
      initialViewState={initialViewState}
      cursor={cursor}
      mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
      // mapStyle="mapbox://styles/mapbox/streets-v11"
      style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}
      interactiveLayerIds={interactiveLayerIds}
      onMouseMove={handleHover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => {
        onMouseLeave();
      }}
      onClick={handleClick}
    >
      {drawType && (
        <DrawControl
          key={drawType}
          position="top-right"
          displayControlsDefault={false}
          controls={{
            polygon: false,
            point: false,
            trash: true,
            // eslint-disable-next-line camelcase
            line_string: false,
            // eslint-disable-next-line camelcase
            combine_features: false,
            // eslint-disable-next-line camelcase
            uncombine_features: false,
          }}
          defaultMode={drawType === "polygon" ? "draw_polygon" : "draw_point"} // "simple_select" | "draw_polygon"
          onCreate={onUpdate}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      )}
      {eugfcLayerConfig.isEnabled && (
        <Source
          type="raster"
          id="eu-observatory-wms"
          tiles={[
            `https://ies-ows.jrc.ec.europa.eu/iforce/gfc2020/wms.py?bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&crs=EPSG:3857&transparent=true&width=256&height=256&layers=gfc2020`,
          ]}
          tileSize={256}
        >
          <Layer
            id="eu-observatory-layer"
            type="raster"
            source="eu-observatory-wms"
            beforeId="road-path"
            paint={{
              "raster-opacity": eugfcLayerConfig.opacity / 100,
              // "raster-color": "#f00",
            }}
          />
        </Source>
      )}
      {plots?.map((plot) => {
        if (plot.json?.geometry.type === "Point") {
          return (
            <Marker
              key={plot.id}
              longitude={plot.json.geometry.coordinates[0]}
              latitude={plot.json.geometry.coordinates[1]}
              color={HIGHLIGHT_GREEN}
              onClick={() => navigate("/plots/show/" + plot.id)}
              style={{ cursor: "pointer" }}
            />
          );
        }

        const outlineOverrides = !currentPlotId
          ? {}
          : currentPlotId === plot.id
            ? { "line-opacity": 1, "line-width": 3 }
            : { "line-opacity": 0.4 };
        // @ts-expect-error - temporary
        const fillOverrides = !currentPlotId
          ? {}
          : currentPlotId === plot.id
            ? { "fill-opacity": 0.4 }
            : { "fill-opacity": 0.2 };
        return (
          <Source key={plot.id} type="geojson" data={plot.json} id={plot.id}>
            <Layer
              id={"polygon-fill-" + plot.id}
              type="fill"
              paint={{
                "fill-color": HIGHLIGHT_GREEN,
                "fill-opacity": 0, // 0.3,
                // ...fillOverrides,
              }}
            />
            <Layer
              id={"polygon-outline-" + plot.id}
              type="line"
              paint={{
                "line-color": HIGHLIGHT_GREEN,
                "line-width": 2,
                "line-opacity": 0.8,
                ...outlineOverrides,
              }}
            />
          </Source>
        );
      })}
      {eugfcLayerConfig.isEnabled &&
        currentPlot?.eugfc_overlap?.filter(isTruthy).map((polygon, i) => {
          const id = currentPlot.id + "-overlap-" + i;
          return (
            <Source type="geojson" data={polygon} id={id} key={id}>
              <Layer
                id="current-plot"
                type="fill"
                paint={{ "fill-color": "#f00", "fill-opacity": 0.4 }}
              />
              <Layer
                id={"polygon-outline-" + id}
                type="line"
                paint={{
                  "line-color": "#f00",
                  "line-width": 1,
                  "line-opacity": 0.8,
                }}
              />
            </Source>
          );
        })}

      {/* {hoverInfo?.feature && (
        <HoverInfo
          x={hoverInfo.x}
          y={hoverInfo.y}
          feature={hoverInfo.feature}
        />
      )} */}

      {/* <Source
        type="raster"
        id="sentinel2"
        tiles={[
          "https://services.sentinel-hub.com/ogc/wms/dcb8c83e-0bdd-468a-8e38-097be2852b94?SERVICE=WMS&REQUEST=GetMap&layers=TRUE-COLOR-S2L2A&bbox={bbox-epsg-3857}&width=256&height=256&srs=EPSG:3857&format=image/jpeg&TIME=2020-12-20T09:02:44Z/2021-01-01T11:00:00Z",
        ]}
        tileSize={256}
        minzoom={15}
      >
        <Layer
          id="sentinel2-layer"
          type="raster"
          source="sentinel2"
          // beforeId="road-path"
          paint={{
            "raster-opacity": 1,
          }}
        />
      </Source> */}

      {currentPlot && <RubberBaseline currentPlot={currentPlot} />}

      <MapInfo />
      <Grid />
      <NavigationControl />
      <LayerControl />
    </Mapbox>
  );
};

type MapProps = {
  children: React.ReactNode;
};

export const MapLayout = ({ children }: MapProps) => {
  return (
    <div className="relative h-screen overflow-hidden">
      <MapElement />
      <div className="relative w-fit">{children}</div>
    </div>
  );
};

export const MapCard = ({ children }: MapProps) => {
  return <Card className="ml-3 mt-3">{children}</Card>;
};

// https://docs.mapbox.com/mapbox-gl-js/example/offset-vanishing-point-with-padding/
