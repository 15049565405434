import { zodResolver } from "@hookform/resolvers/zod";
import { useLogin, useNotification } from "@refinedev/core";
import type { AuthError } from "@supabase/supabase-js";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { z } from "zod";

import type { LoginProps } from "@/authProvider";
import { Button } from "@/components/ui/button";
import { supabaseClient } from "@/utility";

import logo from "../../assets/logo-black.svg";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "../ui/form";
import { Image } from "../ui/image";
import { Input } from "../ui/input";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "../ui/input-otp";
// import { Link } from "../ui/link";

const emailFormSchema = z.object({
  email: z.string().min(1, { message: "Email is required" }).email(),
});
type EmailFormInputs = z.infer<typeof emailFormSchema>;

type EnterEmailPageProps = {
  mode: "login" | "sign-up";
};

export const EnterEmailPage = ({ mode }: EnterEmailPageProps) => {
  const {
    confirmPageUrl,
    title,
    bodyText,
    buttonText,
    // wrongPageText,
    // wrongPageCta,
    // wrongPageTo,
  } =
    mode === "sign-up"
      ? {
          confirmPageUrl: "/sign-up/confirm",
          title: "Enter your email to get started",
          bodyText: "We suggest using the email address you use at work.",
          buttonText: "Continue",
          // wrongPageText: "Already have an account?",
          // wrongPageCta: "Sign in",
          // wrongPageTo: "/login",
        }
      : {
          confirmPageUrl: "/login/confirm",
          title: "Sign in to dilify",
          bodyText: "We suggest using the email address you use at work.",
          buttonText: "Sign in",
          // wrongPageText: "Don't have an account?",
          // wrongPageCta: "Sign up",
          // wrongPageTo: "/sign-up",
        };

  const navigate = useNavigate();
  const notification = useNotification();

  const form = useForm<EmailFormInputs>({
    resolver: zodResolver(emailFormSchema),
    defaultValues: {
      email: "",
    },
  });
  const onSubmit = async ({ email }: EmailFormInputs) => {
    const handleError = (error: AuthError) => {
      notification.open?.({
        type: "error",
        message: "Error" + " " + error.status,
        description: error.message,
      });
    };

    supabaseClient.auth
      .signInWithOtp({ email })
      .then(({ error }) => {
        if (error) {
          handleError(error);
        }
      })
      .catch(handleError);

    navigate(confirmPageUrl, { state: { email } });
  };

  return (
    <>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="grid justify-items-center gap-6">
            <div className="grid gap-2 text-center">
              <h1 className="text-3xl font-bold">{title}</h1>
              <p className="text-balance text-muted-foreground">{bodyText}</p>
            </div>
            <div className="grid w-[340px] gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel htmlFor="email" className="sr-only">
                      Email
                    </FormLabel>
                    <FormControl>
                      <Input
                        {...field}
                        className="h-12 text-base"
                        placeholder="name@work-email.com"
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button type="submit" className="h-12 w-full">
                {buttonText}
              </Button>
            </div>
          </div>
        </form>
      </Form>
      {/* <div className="mt-4 text-center text-sm">
        {wrongPageText}{" "}
        <Link to={wrongPageTo} className="underline">
          {wrongPageCta}
        </Link>
      </div> */}
    </>
  );
};

const otpFormSchema = z.object({
  otp: z.string().min(6, {
    message: "Your one-time password must be 6 characters.",
  }),
});
type OTPFormInputs = z.infer<typeof otpFormSchema>;

type LocationState = { email?: string } | null;

type ConfirmPageProps = {
  mode: "login" | "sign-up";
};

export const ConfirmPage = ({ mode }: ConfirmPageProps) => {
  const resetUrl = mode === "sign-up" ? "/sign-up" : "/login";

  const { mutate: login } = useLogin<LoginProps>();
  const form = useForm<OTPFormInputs>({
    resolver: zodResolver(otpFormSchema),
    defaultValues: {
      otp: "",
    },
  });
  const state = useLocation().state as LocationState;
  const email = state?.email;

  const { handleSubmit, watch } = form;
  useEffect(() => {
    if (!email) {
      return;
    }

    const subscription = watch((data) => {
      if (data.otp?.length === 6) {
        login({ email, otp: data.otp });
      }
    });
    return () => subscription.unsubscribe();
  }, [handleSubmit, watch, login, email]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!email) {
      navigate(resetUrl);
    }
  }, [email, navigate, resetUrl]);
  if (!email) {
    return null;
  }

  return (
    <Form {...form}>
      <form onSubmit={(e) => e.preventDefault()} className="mx-4 max-w-[400px]">
        <FormField
          control={form.control}
          name="otp"
          render={({ field }) => (
            <FormItem className="space-y-4">
              <FormLabel className="text-3xl font-bold">
                Check your email for a code
              </FormLabel>
              <FormDescription className="text-base">
                We&apos;ve sent a 6-digit code to <strong>{email}</strong>. The
                code expires shortly so please enter it soon.
              </FormDescription>
              <FormControl>
                <InputOTP maxLength={6} {...field}>
                  <InputOTPGroup className="mt-4">
                    <InputOTPSlot index={0} />
                    <InputOTPSlot index={1} />
                    <InputOTPSlot index={2} />
                    <InputOTPSlot index={3} />
                    <InputOTPSlot index={4} />
                    <InputOTPSlot index={5} />
                  </InputOTPGroup>
                </InputOTP>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
      </form>
    </Form>
  );
};

type LoginLayoutProps = {
  children: React.ReactNode;
};
export const LoginLayout = ({ children }: LoginLayoutProps) => {
  return (
    <div className="h-screen w-full lg:grid lg:grid-cols-2">
      <div className="flex flex-col items-center pt-20">
        <img className="w-20 pb-20" src={logo} />
        <div className="flex items-center justify-center py-12">
          <div className="mx-auto grid gap-6">{children}</div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block">
        <Image
          src="/images/login-background.png"
          alt="Image"
          width="1920"
          height="1080"
          className="h-full w-full object-cover dark:brightness-[0.2] dark:grayscale"
        />
      </div>
    </div>
  );
};
