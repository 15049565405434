import type { ImgHTMLAttributes } from "react";
import { forwardRef } from "react";

type ImageProps = ImgHTMLAttributes<HTMLImageElement>;

export const Image = forwardRef<HTMLImageElement, ImageProps>(
  ({ src, alt, ...props }, ref) => {
    return <img src={src} alt={alt} {...props} ref={ref} />;
  },
);

Image.displayName = "Image";
