import { validateEnv } from "./schema";

const env = validateEnv(import.meta.env);

export const SENTRY_ENVIRONMENT = env.VITE_SENTRY_ENVIRONMENT;
export const POSTHOG_KEY = env.VITE_POSTHOG_KEY;
export const POSTHOG_API_HOST = env.VITE_POSTHOG_API_HOST;
export const POSTHOG_UI_HOST = env.VITE_POSTHOG_UI_HOST;
export const SUPABASE_URL = env.VITE_SUPABASE_URL;
export const SUPABASE_KEY = env.VITE_SUPABASE_KEY;
export const MAPBOX_TOKEN = env.VITE_MAPBOX_TOKEN;
export const GEE_BUN_URL = env.VITE_GEE_BUN_URL;
