import { useAtom } from "jotai";
import { Layers3 } from "lucide-react";
import { useFeatureFlagEnabled } from "posthog-js/react";
import { useState } from "react";

import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/components/ui/sheet";
import { Slider } from "@/components/ui/slider";
import { Switch } from "@/components/ui/switch";
import { cn } from "@/lib/utils";

import { eugfcLayerConfigAtom, rubberBaselineLayerConfigAtom } from "./state";

export const LayerControl = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [eugfcLayerConfig, setEuGfcLayerConfig] = useAtom(eugfcLayerConfigAtom);
  const [rubberBaselineLayerConfig, setRubberBaselineLayerConfig] = useAtom(
    rubberBaselineLayerConfigAtom,
  );

  const isRubberBaselineEnabled = useFeatureFlagEnabled("demo-only");

  return (
    <div className="absolute right-2.5 top-28 z-10 flex overflow-hidden rounded-sm shadow-[0_0_0_2px_rgba(0,0,0,0.1)]">
      <Sheet open={isOpen} onOpenChange={setIsOpen}>
        <SheetTrigger asChild>
          <button className="flex h-[29px] w-[29px] items-center justify-center bg-white">
            <Layers3 size={16} />
          </button>
        </SheetTrigger>
        <SheetContent>
          <SheetHeader>
            <SheetTitle>Map layers</SheetTitle>
          </SheetHeader>
          <ScrollArea className="-mr-3 mt-3 h-[calc(100vh-6rem)]">
            <div className="space-y-3 pr-3">
              <div className="rounded-lg border p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-semibold uppercase">
                    EU Global Forest Cover 2020
                  </h3>
                  <Switch
                    checked={eugfcLayerConfig.isEnabled}
                    onCheckedChange={(checked) => {
                      setEuGfcLayerConfig((prev) => ({
                        ...prev,
                        isEnabled: checked,
                      }));
                    }}
                  />
                </div>
                <div>
                  <div
                    className={cn(
                      "mt-4",
                      !eugfcLayerConfig.isEnabled && "opacity-50",
                    )}
                  >
                    <h3 className="mb-2 text-sm font-semibold">Opacity</h3>
                    <Slider
                      max={100}
                      step={1}
                      className="w-full"
                      value={[eugfcLayerConfig.opacity]}
                      onValueChange={(value) =>
                        setEuGfcLayerConfig((prev) => ({
                          ...prev,
                          opacity: value[0],
                        }))
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <h3 className="text-sm font-semibold">Resolution</h3>
                    <p className="text-xs">~10m</p>
                  </div>
                  <div className="mt-4">
                    <h3 className="mt-2 text-sm font-semibold">Source</h3>
                    <a
                      href="https://publications.jrc.ec.europa.eu/repository/handle/JRC136960"
                      className="text-xs underline"
                      target="_blank"
                      rel="noreferrer"
                    >
                      JRC: Global Forest Cover of the Year 2020
                    </a>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-sm font-semibold">Description</h3>
                    <p className="text-xs">
                      This data layer was created by the EU Observatory to
                      provide a single harmonized globally-consistent
                      representation of where forests existed in 2020. The EU
                      has stated that this one of many tools that may be used to
                      inform EUDR risk assessment. Other granular maps may also
                      be used to inform risk assessments.
                    </p>
                  </div>
                </div>
              </div>

              <div className="rounded-lg border p-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-sm font-semibold uppercase">
                    Rubber Baseline
                  </h3>
                  {isRubberBaselineEnabled ? (
                    <Switch
                      checked={rubberBaselineLayerConfig.isEnabled}
                      onCheckedChange={(checked) => {
                        setRubberBaselineLayerConfig((prev) => ({
                          ...prev,
                          isEnabled: checked,
                        }));
                      }}
                    />
                  ) : (
                    <Badge variant="secondary">Coming Soon</Badge>
                  )}
                </div>

                <div>
                  <div
                    className={cn(
                      "mt-4",
                      !rubberBaselineLayerConfig.isEnabled && "opacity-50",
                    )}
                  >
                    <h3 className="mb-2 text-sm font-semibold">Opacity</h3>
                    <Slider
                      max={100}
                      step={1}
                      className="w-full"
                      value={[rubberBaselineLayerConfig.opacity]}
                      onValueChange={(value) =>
                        setRubberBaselineLayerConfig((prev) => ({
                          ...prev,
                          opacity: value[0],
                        }))
                      }
                    />
                  </div>
                  <div className="mt-4">
                    <h3 className="text-sm font-semibold">Resolution</h3>
                    <p className="text-xs">~10m</p>
                  </div>
                  <div className="mt-4">
                    <h3 className="mt-2 text-sm font-semibold">Source</h3>
                    <span className="text-xs">Dilify Rubber Algorithm</span>
                  </div>
                  <div className="mt-4">
                    <h3 className="text-sm font-semibold">Description</h3>
                    <p className="text-xs">
                      This data layer shows where rubber plantations were
                      located at at the cutoff date of December 31, 2020. The
                      algorithm was created by Dilify and is based on a range of
                      data sources, including satellite imagery, machine
                      learning, and expert knowledge.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ScrollArea>
        </SheetContent>
      </Sheet>
    </div>
  );
};
