import { PlusIcon, UploadCloud } from "lucide-react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

export const ImportButton = () => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Import Trades</Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Import Trade Data</DialogTitle>
          <DialogDescription>
            Choose a method to import your trade data.
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="manual" className="w-full">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="manual">Manual Upload</TabsTrigger>
            <TabsTrigger value="integrations">Integrations</TabsTrigger>
          </TabsList>
          <TabsContent value="manual">
            <div className="flex w-full items-center justify-center">
              <label
                htmlFor="dropzone-file"
                className="flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed border-stone-300 bg-stone-50 hover:bg-stone-100"
              >
                <div className="flex flex-col items-center justify-center pb-6 pt-5">
                  <UploadCloud className="h-10 w-10 text-stone-500" />
                  <p className="mb-2 mt-2 text-sm text-stone-500">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-stone-500">CSV, XLS</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" />
              </label>
            </div>
          </TabsContent>
          <TabsContent value="integrations">
            <div className="grid grid-cols-2 gap-4">
              <Button
                variant="outline"
                className="flex h-32 flex-col items-center justify-center bg-stone-100 text-stone-500"
              >
                <img
                  src="https://asset.brandfetch.io/idxLNTSrGB/idxjwy2PJc.png"
                  alt="Flexport"
                  className="mb-4 h-10 w-10"
                />
                <span>Flexport</span>
              </Button>
              <Button
                variant="outline"
                className="flex h-32 flex-col items-center justify-center bg-stone-100 text-stone-500"
              >
                <PlusIcon className="mb-4 h-10 w-10" />
                <span>Add your integration</span>
              </Button>
            </div>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};
